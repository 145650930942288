<template lang="pug">
.main-wrapper.transacoes-listar
        header.main-wrapper-header
            .p-grid.p-align-center
                .p-col-12.ta-right
                    h1.text-header.text-secondary MedClub / Caixas / <b>Configurar Troco</b>

        Dialog.dialogApagar(header='Remover Configuração de Troco' :visible.sync='dialogApagar' :modal='true')
            p Deseja remover a Configuração de Troco do(s) Usuario(s) <b v-for="item in dialogApagar_data.cd_usuarios">{{ item.first_name }},</b> do Caixa Supervisão
                <b>{{ dialogApagar_data.cd_caixa_supervisor.nm_caixa }}</b>, de vigência <b>{{ dialogApagar_data.dt_vigencia_inicial }}</b> até <b>{{ dialogApagar_data.dt_vigencia_inicial }}</b>?
            .ta-center.my-4
                ProgressSpinner(v-if='waitingApagar' strokeWidth='6')
                Button.p-button-danger(v-else label='Remover' @click='remove()')

        Panel.mb-2(header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576')
            .p-grid.p-fluid.p-align-end

                .p-col-12.p-md-6
                    label.form-label Caixa Supervisão:
                    ProgressBar(v-if="waitingCaixas" mode="indeterminate")
                    MultiSelect(v-else v-model='filters.caixas_ids' :options='options.caixas' filter
                        dataKey='id' optionLabel='nm_caixa' optionValue='id' placeholder='- TODOS -' @hide='applyFilters()' )
                .p-col-12.p-md-6
                    label.form-label Usuário(s):
                    ProgressBar(v-if="waitingFuncionarios" mode="indeterminate")
                    MultiSelect(v-else v-model='filters.funcionarios_ids' :options='options.funcionarios' filter
                        dataKey='id' optionLabel='text' optionValue='value' placeholder='- TODOS -' @hide='applyFilters()' )

                .p-col-12.p-md-12.ta-right.mt-4
                    Button(label='Limpar filtros' icon='jam jam-rubber' @click='clearFilters()' style="max-width: 24% !important;")

        .ta-right.mt-4
            Button(label='Adicionar' icon="jam jam-plus" @click="$router.push('/troco/salvar/0/')")
            br
            br

        ProgressBar(v-if='waiting' mode="indeterminate")
        div(v-else-if='list.length == 0')
            p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.
        div(v-else)

            Panel.datatable(header='Lista de configurações de Troco')
                Paginator.mb-1(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
                DataTable(ref='datatableConfigTroco' :value="list"
                    dataKey="id"
                    :rowClass="rowClass"
                )
                    Column(headerStyle='width: 20%;' field='cd_caixa_supervisor.nm_caixa' header='Caixa Supervisão')
                    Column(headerStyle='width: 30%;' field="cd_usuarios" header='Usuários')
                        template(#body='props')
                            ul(v-for="item in props.data.cd_usuarios")
                                li {{ item.first_name }} (username: {{ item.username }})
                    Column(headerStyle='width: 10%;' bodyStyle='text-align: left;' header='Valor')
                        template(#body='props')
                            span {{ formatPrice(props.data.nr_valor) }}
                    Column(headerStyle='width: 20%;' bodyStyle='text-align: center;' field='dt_vigencia_inicial_f' header='Data Inicial de Vigência')
                    Column(headerStyle='width: 20%;' bodyStyle='text-align: center;' field='dt_vigencia_final_f' header='Data Final de Vigência')
                    Column(headerStyle='width: 10%; text-align: center')
                        template(#header) Ações
                        template(#body='props')
                            .ta-center
                                Button.p-button-raised.p-button-rounded.mr-1(
                                    :disabled="!verificarVigencia(props.data.dt_vigencia_final)"
                                    v-tooltip.top="'Editar'"
                                    icon="jam jam-write"
                                    @click="$router.push(`/troco/salvar/${ props.data.id }/`)"
                                )
                                Button.p-button-raised.p-button-rounded.p-button-danger(
                                    :disabled="!verificarVigencia(props.data.dt_vigencia_final)"
                                    v-tooltip.top="'Apagar'"
                                    icon="jam jam-minus-circle"
                                    @click="dialogApagar = true; dialogApagar_data = props.data"
                                )
                Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
</template>

<style lang="scss">
.transacoes-listar {
    .dataview {
        @media all and (min-width: 577px) {
            display: none;
        }
    }
    .datatable {
        @media all and (max-width: 576px) {
            display: none;
        }
        .cell {
            padding: 16px 0;
            text-align: center;
            &.ex  {  background-color: #e4f8e1; }
            &.a  { background-color: #faf3dd; }
        }
    }
    .dialogApagar {
        width: 92%;
        max-width: 500px;
        p {
            margin: 4px 0;
        }
    }
    .link-limpar {
        text-decoration: underline;
        font-size: 12px;
        cursor: pointer;
        font-weight: 700;
        margin-right: 10px;
        display: inline-block;
        margin-bottom: 6px;
    }
    .text-soma {
        margin-bottom: 4px;
        margin-right: 10px;
        display: inline-block;
        background-color: #faf3dd;
        box-shadow: 0 1px 2px #ddd;
        padding: 6px 10px;
    }
}
</style>

<script>
import ProgressBar from 'primevue/progressbar'
import DataView from 'primevue/dataview'
import Panel from 'primevue/panel'
import Paginator from 'primevue/paginator'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import InputText from 'primevue/inputtext'
import Calendar from 'primevue/calendar'
import InputMask from 'primevue/inputmask'
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'
import ProgressSpinner from 'primevue/progressspinner'
import Dropdown from 'primevue/dropdown'
import MultiSelect from 'primevue/multiselect'
import moment from 'moment'
import {Caixas, Funcionario} from '../../middleware'
import { pCalendarLocale_ptbr } from './../../utils'
import wsConfigs from './../../middleware/configs'
import axios from "axios";
import Tooltip from 'primevue/tooltip'
const _ = require('lodash')

export default {
    beforeRouteEnter (to, from, next) {
			next(vm => {
				let routeName = vm.$route.path.split('/')[1]
				if ((from.path.split('/')[1] === routeName || from.path.split('/')[1] === 'troco') && vm.$store.state.filters)
					vm.filters = vm.$store.state.filters
				vm.$store.commit('clearFilters')
				vm.applyFilters()
			})
		},
		beforeRouteLeave (to, from, next) {
			let routeName = this.$route.path.split('/')[1]
			if (to.path.split('/')[1] === routeName)
				if (this.filters) this.$store.commit('setFilters', this.filters)
			next()
		},

    created () {
        this.getCaixas()
        this.getFuncionarios()
        // this.applyFilters()
    },
    components: { ProgressBar, DataView, Panel, Paginator, DataTable, InputMask, MultiSelect,
        Column, Button, Dialog, ProgressSpinner, InputText, Calendar, Dropdown },
    directives: {tooltip: Tooltip},
    data () {
        return {
            list: [],
            ptbr: pCalendarLocale_ptbr,
            windowInnerWidth: window.innerWidth,
            waiting: false,
            waitingCaixas: false,
            waitingFuncionarios: false,
            waitingApagar: false,
            dialogApagar: false,
            dialogApagar_data: {cd_caixa_supervisor: {}},
            filters: {
                caixas_ids: [],
                funcionarios_ids: [],
                unidades_medclub: [],
            },
            paginator: {page: 1,
                per_page: wsConfigs.paginator_perPage,
                count: 0
            },
            options: {
                caixas: [],
                funcionarios: [],
            },
            selected: null,
            dialogVisualizar: false,
            cancelToken: null,
            params: {},
            getListDebounce: _.debounce(function (params, getFunction) {
                    getFunction(params)
                }, 500, { leading: true }),
            sum () {
                if (! this.selected) return 0
                let sum = 0
                this.selected.forEach(item => { sum += item.nr_valor })
                return sum
            },
        }
    },
    methods: {
        getList (params) {
            if (this.cancelToken) this.cancelToken.cancel()
            this.cancelToken = axios.CancelToken.source()
            this.waiting = true
            return Caixas.findAllConfigTroco(params, this.cancelToken.token).then(response => {
                if (!response) return
                if (response.status === 200) {
                    this.paginator.count = response.data.count;
                    response.data.results.forEach((config_troco) => {
                        if (config_troco.dt_vigencia_inicial)
                            config_troco.dt_vigencia_inicial_f = moment(
                                config_troco.dt_vigencia_inicial
                            ).format("DD/MM/YYYY");
                        if (config_troco.dt_vigencia_final)
                            config_troco.dt_vigencia_final_f = moment(
                                config_troco.dt_vigencia_final
                            ).format("DD/MM/YYYY");
                    });
                    this.list = response.data.results;
                }
                this.waiting = false;
                this.cancelToken = null;

                setTimeout(() => {
                    if (this.selected) {

                        let checked = false
                        this.selected.forEach(item => {
                            let el = document.querySelector(`._row_${ item.id }`)
                            if (el && !checked) {
                                checked = true
                                el.click()
                            }
                        })
                        if (checked) this.selected.pop()
                    }
                })

                return true
            })
        },
        getFuncionarios () {
            this.waitingFuncionarios = true
            Funcionario.findAllByUnidade({ cd_unidade_list: this.filters.unidades_medclub }).then(response => {
                this.waitingFuncionarios = false
                if (response.status === 200) {
                    this.options.funcionarios = response.data.map(
                        (funcionario) => {
                            return {
                                text: funcionario.username,
                                value: funcionario.id,
                            };
                        }
                    );
                    this.filters.unidades_medclub = [];
                }
            });
        },
        getCaixas() {
            this.waitingCaixas = true
            Caixas.findAll({ie_tipo: 'S', ie_usuario: true}).then(response => {
                    this.waitingCaixas = false
                    if (response.status === 200) {
                        this.options.caixas = response.data
                        for(let caixa in response.data){
                            this.filters.unidades_medclub.push(...response.data[caixa].cd_unidades)
                        }
                    }
                    this.waiting = false
            })
        },
        checkEmptyField (field) {
            if (! this.filters[field].length) this.applyFilters()
        },
        applyFilters() {
            let params = {
                paginacao: true,
                page: this.paginator.page,
                per_page: this.paginator.per_page,
                ie_usuario: true
            };
            if (this.$route.query.pg != this.paginator.page)
                this.$router.replace({ query: { pg: this.paginator.page, }});
            Object.keys(this.filters).forEach((key) => {
                if (key.substr(0, 3) == 'dt_') {
                    if (this.filters[key]) params[key] = moment(this.filters[key]).format('YYYY-MM-DD')
                } else if (this.filters[key]) params[key] = this.filters[key]
            })
            // Fake loading quando os filtros forem os mesmos
            if (_.isEqual(this.params, params)) {
                if (!this.waiting) {
                    this.waiting = true
                    setTimeout(() => this.waiting = false, 300)
                }
            } else {
                this.params = Object.assign({}, params)
                this.waiting = true
                this.getListDebounce(params, this.getList)
            }
        },
        formatPrice (val) {
            return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val)
        },
        onPage (ev) {
            this.paginator.page = ev.page + 1
            this.applyFilters()
        },
        visualizar (id) {
            this.$router.push(`/transacoes-financeiras/visualizar/${ id }/`)
        },
        rowClass (a) {
            return `_row_${ a.id }`
        },
        limparData(key) {
            this.filters[key] = moment()._d
            if (this.filters.dt_fim < this.filters.dt_inicio) {
                this.filters['dt_fim' !== key && 'dt_fim' || 'dt_inicio'] = moment()._d
            }
        },
        limparSelecao () {
            this.selected = null
        },
        clearFilters () {
            this.paginator.page = 1
            Object.keys(this.filters).forEach(k => { this.filters[k] = [] })
            this.applyFilters()
        },
        verificarVigencia(dtVigenciaFinal) {
            if(!dtVigenciaFinal) return true
            const hoje = new Date();
            const dataFinal = new Date(dtVigenciaFinal);
            return  dataFinal > hoje;
        },
        remove () {
            this.waitingApagar = true
            Caixas.removeConfigTroco(this.dialogApagar_data.id).then(response => {
                    this.waitingApagar = false
                    if (([200, 201, 204]).includes(response.status)) {
                        this.$toast.info('Removido com sucesso', { duration: 3000 })
                        this.dialogApagar = false
                        this.applyFilters()
                    } else if (response.data.detail) {
                        if (typeof response.data.detail == 'string')
                            this.$toast.error(response.data.detail, { duration: 3000 })
                        else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                    }
                })
        },
    }
}
</script>
